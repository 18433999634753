import React, { useState, useEffect } from 'react'
import { Button } from "./ui/button"
import { ArrowRight, Mountain } from "lucide-react"

const screenshots = [
  "/screenshot-1.jpg",
  "/screenshot-2.jpg",
  "/screenshot-3.jpg",
  "/screenshot-4.jpg",
  "/screenshot-5.jpg",
]

const galleryImages = [
  "/gallery-1.jpg",
  "/gallery-2.jpg",
  "/gallery-3.jpg",
  "/gallery-4.jpg",
  "/gallery-5.jpg",
  "/gallery-6.jpg",
]

export default function LandingPage() {
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % screenshots.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const scrollToFeatures = () => {
    const featuresSection = document.getElementById('features')
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="flex flex-col min-h-screen font-sans">
      <header className="px-4 lg:px-6 h-14 flex items-center bg-gray-50">
        <a className="flex items-center justify-center" href="#">
          <img src={process.env.PUBLIC_URL + "/logo.png"} style={{ width: '100px' }}></img>
          <span className="sr-only">Supermodel AI</span>
        </a>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#features">
            FEATURES
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#gallery">
            GALLERY
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#download">
            DOWNLOAD
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="mailto:contact@supermodelai.anonaddy.com">
            CONTACT US
          </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-24 xl:py-24 bg-gray-50">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
              <div className="flex flex-col justify-center space-y-4">
                <div className="space-y-2">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                    Generates Stunning Photos of you with AI
                  </h1>
                  <p className="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                    Create stunning, realistic photos of yourself in hundreds of unique styles. Unleash your creativity with <b>Supermodel AI</b>.
                  </p>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row">
                  <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
                    <img
                      src={process.env.PUBLIC_URL + "/download-on-appstore.svg"}
                      alt="Download on the App Store"
                      width={185}
                      height={50}
                    />
                  </a>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="relative w-[280px] h-[572px] bg-gray-900 rounded-[60px] border-[14px] border-gray-800 overflow-hidden shadow-xl">
                  <div className="absolute top-0 left-1/2 -translate-x-1/2 w-1/3 h-[18px] bg-gray-800 rounded-b-[1rem]" />
                  <div className="absolute bottom-2 left-1/2 -translate-x-1/2 w-1/3 h-1 bg-gray-800 rounded-full" />
                  <div className="w-full h-full overflow-hidden">
                    {screenshots.map((src, index) => (
                      <img
                        key={index}
                        src={process.env.PUBLIC_URL + src}
                        alt={`App Screenshot ${index + 1}`}
                        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
                          index === currentSlide ? 'opacity-100' : 'opacity-0'
                        }`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12">Unleash Your Creativity</h2>
            <div className="grid gap-12 md:grid-cols-3">
              <div className="space-y-4">
                <div className="inline-block p-3 rounded-full bg-gray-100 text-center">
                  <svg
                    className="w-6 h-6 text-gray-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold">Hundreds of Styles</h3>
                <p className="text-gray-500">Choose from a vast library of artistic styles and themes to transform your photos.</p>
              </div>
              <div className="space-y-4">
                <div className="inline-block p-3 rounded-full bg-gray-100">
                  <svg
                    className="w-6 h-6 text-gray-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold">Realistic Results</h3>
                <p className="text-gray-500">Our AI ensures your transformed photos look incredibly lifelike and stunning.</p>
              </div>
              <div className="space-y-4">
                <div className="inline-block p-3 rounded-full bg-gray-100">
                  <svg
                    className="w-6 h-6 text-gray-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold">Fast Processing</h3>
                <p className="text-gray-500">Get your AI-generated photos in seconds, not minutes. Create and share quickly.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="gallery" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12">Gallery</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {galleryImages.map((src, index) => (
                <div key={index} className="relative aspect-square overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={process.env.PUBLIC_URL + src}
                    alt={`Generated image ${index + 1}`}
                    className="absolute inset-0 h-full w-full object-cover transition-transform duration-300 hover:scale-110"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="download" className="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Ready to Transform Your Photos?</h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  Download Supermodel AI now and start creating stunning, realistic photos in hundreds of unique styles.
                </p>
              </div>
              <div className="space-y-2">
                <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
                  <img
                    src={process.env.PUBLIC_URL + "/download-on-appstore.svg"}
                    alt="Download on the App Store"
                    width={200}
                    height={60}
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">© 2024 Supermodel AI. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs hover:underline underline-offset-4" href="https://hospitable-bonsai-784.notion.site/Privacy-Policy-for-Supermodel-AI-8107390b90ef4e8ebdcfd55514228b5c">
            Privacy
          </a>
          <a className="text-xs hover:underline underline-offset-4" href="https://hospitable-bonsai-784.notion.site/LICENSED-APPLICATION-END-USER-LICENSE-AGREEMENT-FOR-SUPERMODEL-AI-10a62240a432802b8bb2ff8fc7366ef8?pvs=74">
            Terms of Use
          </a>
        </nav>
      </footer>
    </div>
  )
}